export const SERVER_URL = 'https://dexther-api.proskillowner.com'
// export const SERVER_URL = 'https://dexther-api.mnconcept.one'

export const API_GET_USER = '/getuser'
export const API_GET_CONFIG = '/getconfig'
export const API_SET_CONFIG = '/setconfig'
export const API_GET_OPERATION_LOG = '/getoperationlog'
export const API_GET_CHAIN = '/getchain'
export const API_GET_POOL_LOG = '/getpoollog'
export const API_IS_SYNC_CHECKED = '/issyncchecked'
export const API_GET_SCAN_SCORE = '/getscanscore'
export const API_SET_SCAN_SCORE = '/setscanscore'